import { PropertyCellLabelComponent } from "lib/controls/PropertyGridControl";
import SkillSearchForm from "./SkillSearchForm";
import SearchPageView from "lib/pages/SearchPageView";
import { Component, Form } from "Bent";


export default class SkillSearchView extends SearchPageView<SkillSearchForm> {

	public populate(): void {
		this.form.header.caption.text = 'Søgekriterier til kvalifikvationer';
		this.form.resetButton.setContent(null, 'Nulstil');
		this.form.searchButton.setContent(null, 'Søg');

	}


	public populateFieldCaption(fieldName: string, labelCell: PropertyCellLabelComponent) {
		let t = {
			'name': 'Navn',
			'category' : 'Kategory',
			'status': 'Status',
			'createdBy': 'Oprettet af',
			'language': 'Sprog',
		}
		labelCell.text = t[fieldName];

	}

	constructor(parent: SkillSearchForm){
		super(parent);

		this.rowCount = 1;
	}
}